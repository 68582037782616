import React, { useState } from "react";
import { VscChromeClose } from "react-icons/vsc";
import { VscChevronLeft } from "react-icons/vsc";
import { VscChevronRight } from "react-icons/vsc";
import Masonry from "react-masonry-css";
import { getGlobal } from 'reactn';
import axios from "axios";
import Card from "../components/Card";
 
function GalleryVideo(props) {
  
  const [slideIndex, setSlideIndex] = useState(1);

  const [publicToggleError, setPublicToggleError] = useState(false);

  const nextSlide = () => {
    if (slideIndex !== props.data.length) {
      setSlideIndex(slideIndex + 1);
    } else if (slideIndex === props.data.length) {
      setSlideIndex(1);
    }
  };
  const prevSlide = () => {
    if (slideIndex !== 1) {
      setSlideIndex(slideIndex - 1);
    } else if (slideIndex === 1) {
      setSlideIndex(props.data.length);
    }
  };

  const [closeLightBox, setCloseLightBox] = useState(true);

  function ToggleLightBox(index) {
    setPublicToggleError(false);
    setCloseLightBox(!closeLightBox);
    setSlideIndex(index);
  }

  const moveDot = (index) => {
    setSlideIndex(index);
  };

  function BtnSlider({ direction, moveSlide }) {
    return (
      <button
        onClick={moveSlide}
        className={`btn-slide ${direction === "next" ? "next" : "prev"}`}
      >
        {direction === "next" ? <VscChevronRight /> : <VscChevronLeft />}
      </button>
    );
  }



  function handlePublicToggle(file_id, file_status) {

    const formData = new FormData();
    formData.append("Token", getGlobal().Token);
    formData.append("CSRFtoken", getGlobal().csrfToken);
    formData.append("formID", props.formID);
    formData.append("entryID", props.entryID);

    

    const doStatusChange = async () => {

      setPublicToggleError(false);

      var apiARL = getGlobal().ApiURL + "/toggle-upload-privacy/" + file_id + "/" + file_status + "/";
      const res = await axios.post(apiARL, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (res.data.status === 1) {

        
      } else {

        setCloseLightBox(true);
        setPublicToggleError(true);

      }
      return true;
    }   

    return doStatusChange();
  };






  return (
    <>
      

      
      {publicToggleError ? (
        <>
          <Card alert="error" closable>
            <p>ERROR UPDATING VIDEO AVAILABLITY TOGGLE!</p>
          </Card>
        </>
      ) : null}
      
      
      <div className="row gallery">
        <div className="col-12">{props.title && <h2>{props.title}</h2>}</div>
        <Masonry
          className="gallery-grid"
          columnClassName="gallery-column"
          breakpointCols={props.cols}
          style={{width:'100%'}}
        >
          {props.data.map((obj, index) => { 
            return (
              <div key={obj.id} onClick={() => ToggleLightBox(index + 1)}>
                <video preload="none" poster={getGlobal().cdnURL + "/" + obj.thumburl} src={getGlobal().cdnURL + "/" + obj.url} style={{ width:'100%', cursor: "pointer"}}>
                  Your browser does not support the video tag.
                </video>
              </div>
              
              // <img
              //   key={obj.id}
              //   src={getGlobal().cdnURL + "/" + obj.thumburl}
              //   alt=""
              //   onClick={() => ToggleLightBox(index + 1)}
              // />
            );
          })}
        </Masonry>
      </div>

      {props.lightbox && (
        <div className={`lightbox-container${closeLightBox ? "" : " open"}`}>
          <VscChromeClose className="lightbox-close" onClick={ToggleLightBox} />
          <div className="slides-container">
            {props.data.map((obj, index) => {
              return (
                <div
                  className={`slide${slideIndex === index + 1 ? " active" : ""
                    }`}
                  key={obj.id}
                >
             
                    <video style={{width:'100%'}} preload="none" poster={getGlobal().cdnURL + "/" + obj.thumburl} src={getGlobal().cdnURL + "/" + obj.url} controls>
                      Your browser does not support the video tag.
                    </video>

                  <div className="slide-file">
                    {obj.id + "/" + props.data.length + " - "}
                    <span>{obj.fileName}</span>


                    
                  </div>
                  
                  <div className="lightbox-dots alt">


                    <div
                      className={["lightbox-dot", obj.video_status === 1 ? "active" : null].join(" ")}
                      onClick={(e) => {
                        if (e.target.classList.contains('active')) {
                          handlePublicToggle(obj.id, 1);
                        } else {
                          handlePublicToggle(obj.id, 0);
                        } 
                        e.target.classList.toggle('active'); 
                      }}
                    >

                      <div className="slide-file alt public-notice">This video is public</div>
                      <div className="slide-file alt private-notice">This video is private</div>

                    </div>
                    
                  </div>
                  
                </div>
              );
            })}
            <BtnSlider moveSlide={prevSlide} direction={"prev"} />
            <BtnSlider moveSlide={nextSlide} direction={"next"} />




            <div className="lightbox-dots">
              {props.data.map((obj, index) => {
                return (
                  <div
                    className={`lightbox-dot${slideIndex === index + 1 ? " active" : ""
                      }`}
                    onClick={() => moveDot(index + 1)}
                    key={obj.id}
                  ></div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default GalleryVideo;