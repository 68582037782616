import React, { useState } from "react";
import Collapse from "./Collapse";
import Card from "./Card";

function CardCollapse(props) {
  const [clickToggle, setClickToggle] = useState(true);
  function ButtonClick() {
    setClickToggle(!clickToggle);
  }

  const open = props.open;

  return (
    <Card className="card-collapse">
      <Collapse
        label={
          <div className="parent" onClick={ButtonClick}>
            {props.title && <h2>{props.title}</h2>}
          </div>
        }
        open={open}
      >
        {props.children}
      </Collapse>
    </Card>
  );
}

export default CardCollapse;
