import { useState, useRef, useEffect } from "react";
import axios from "axios";
import { getGlobal } from 'reactn';

export const APIget = (request) => {

  const isMounted = useIsMounted();

  const url = getGlobal().ApiURL + request;

  const [data, setData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const fetchData = () => {

      const formData = new FormData();
      formData.append('Token', getGlobal().Token);

      axios.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(function (response) {
        if (isMounted.current) {
          setData(response.data);
          setIsLoaded(true);
        }

      })
        .catch(function (error) {
        });
    };
    fetchData();
  }, [url, isMounted]);

  return { isLoaded, data };

}
export default APIget;



export function useIsMounted() {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => isMounted.current = false;
  }, []);

  return isMounted;
}
