import {
  //VscPreview,
  //VscOutput,
  //VscHome,
  VscTriangleRight,
  //VscSettingsGear,
  VscGist,
  VscFiles,
  //VscInbox,
  VscSignOut,
  //VscArchive,
  VscDeviceCamera
} from "react-icons/vsc";

const SideMenuData = [
  // {
  //   title: "Submissions",
  //   path: "/submissions/",
  //   icon: <VscInbox />,
  //   iconOpen: <VscTriangleRight />,
  // },
  {
    title: "Forms",
    path: "/view-forms/",
    icon: <VscFiles />,
    iconOpen: <VscTriangleRight />,
  },
  {
    title: "Scan QR",
    path: "/scan-qr/",
    icon: <VscDeviceCamera />,
    iconOpen: <VscTriangleRight />,
  },
  // {
  //   title: "Create Form",
  //   path: "/create-form/",
  //   icon: <VscGist />,
  //   iconOpen: <VscTriangleRight />,
  // },
  // {
  //   title: "System Settings",
  //   path: "/system-settings/",
  //   icon: <VscSettingsGear />,
  //   iconOpen: <VscTriangleRight />,
  // },
  // {
  //   title: "System Log",
  //   path: "/system-log/",
  //   icon: <VscArchive />,
  //   iconOpen: <VscTriangleRight />,
  // },
  {
    title: "Log Out",
    path: "/logout/",
    icon: <VscSignOut />,
    iconOpen: <VscTriangleRight />,
  },

  
  // {
  //   title: "DEMO STUFF",
  //   path: "/404",
  //   icon: <VscOutput />,
  //   iconOpen: <VscTriangleRight />,

  //   subNav: [
  //     {
  //       title: "Home",
  //       path: "/",
  //       icon: <VscHome />,
  //     },
  //     {
  //       title: "X-thusiast",
  //       path: "/forms/x-thusiast",
  //       icon: <VscPreview />
  //     },
  //     {
  //       title: "Single template",
  //       path: "/forms/x-thusiast/single",
  //       icon: <VscPreview />
  //     },
  //     {
  //       title: "All Forms",
  //       path: "/forms",
  //       icon: <VscPreview />
  //     },
  //     {
  //       title: "Cards",
  //       path: "/cards",
  //       icon: <VscOutput />,
  //       iconOpen: <VscTriangleRight />,
  //     },
  //     {
  //       title: "Table",
  //       path: "/table",
  //       icon: <VscOutput />,
  //       iconOpen: <VscTriangleRight />,
  //     },
  //     {
  //       title: "Gallery",
  //       path: "/gallery",
  //       icon: <VscOutput />,
  //       iconOpen: <VscTriangleRight />,
  //     },
  //   ],
  // }



];

export default SideMenuData;
