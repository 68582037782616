
import Cookies from "js-cookie";



export function setCookie(name, value, ttl) {

    if (window.location.protocol === 'https:') {
        Cookies.set(name, value, { secure: true, sameSite: 'strict', path: '/', expires: ttl })
    }
    if (window.location.protocol === 'http:') {
        Cookies.set(name, value, { sameSite: 'strict', path: '/', expires: ttl })        
    }


}
export function setCookieTemp(name, value) {

    var expires = "";
    expires = "; expires=DateTime.MinDate;secure";
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}
export function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}
export function eraseCookie(name) {
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}
