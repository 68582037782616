import React, { useState } from "react";

import LoginForm, {LostPasswordForm} from '../components/LoginForm.js';
import TwoFactorForm from '../components/TwoFactorForm.js';

function Login(props) {

  const [viewingForm, setViewingForm] = useState('login');

  return (
    <>
      {viewingForm === "login" ? (<LoginForm setViewingForm={setViewingForm} />) : null}
      {viewingForm === "2fa" ? (<TwoFactorForm setViewingForm={setViewingForm} />) : null}
      {viewingForm === "lostpw" ? (<LostPasswordForm setViewingForm={setViewingForm} />) : null}
    </>
  );
}
export default Login;
