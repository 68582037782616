import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { setGlobal, getGlobal, addCallback } from 'reactn';

import { getCookie } from './helpers/Cookies.js';
import setFormToken from './helpers/setFormToken.js';

import { CgSun } from "react-icons/cg";
import { CgMoon } from "react-icons/cg";

import Login from "./pages/Login";
import LogOut from "./pages/LogOut";
//import Home from "./pages/Home";
import ViewForms from "./pages/ViewForms";
import ViewFormData from "./pages/ViewFormData";
import DataEntry from "./pages/DataEntry";
import ScanQr from "./pages/ScanQr";


import Table from "./pages/demo/Table";
import Cards from "./pages/demo/Cards";
import Gallery from "./pages/demo/Gallery";
import { Forms } from "./pages/demo/Forms";
import XThusiastSingle from "./pages/demo/XThusiastSingle";
import XThusiastForms from "./pages/demo/XthusiastTable";

import SideMenu from "./components/SideMenu";
import ToggleSwitch from "./components/ToggleSwitch";

import "./scss/style.scss";
import Footer from "./components/Footer";
import NotFound from "./components/NotFound";



let P_PAT_Token = getCookie('P_PAT_Token');
var get_DarkMode = localStorage.getItem("DarkMode");

var loggedInCookie = 0;
if (P_PAT_Token !== null) { loggedInCookie = 1; }


var SavedDarkMode = false;
if (get_DarkMode !== null) { SavedDarkMode = get_DarkMode; }





setGlobal({
  LoggedIn: loggedInCookie,
  Token: P_PAT_Token,
  
  ApiURL: process.env.REACT_APP_API_URL,
  cdnURL: process.env.REACT_APP_STATIC_API_URL,

  csrfToken: "",
  twoFaToken: "",
  systemVer: "v1.0.0",
  DarkMode: SavedDarkMode,
});


function App() {

  setFormToken();

  const [darkMode, setDarkMode] = useState(SavedDarkMode);
  const [loggedin, setLoggedin] = useState(getGlobal().LoggedIn);

  addCallback(function () {
    setTimeout(function () {
      setLoggedin(getGlobal().LoggedIn)
    }, 10);
  });


  if (loggedin === 0) {
    return (<><Login /></>);
  }

  if (loggedin === 1) {
    return (
      <>
        <Router>
          <div className="theme" data-theme={darkMode === true || darkMode === "true" ? "dark" : "light"}>
            <SideMenu>
              <ToggleSwitch
                checked={get_DarkMode}
                onChange={
                  (event) => {
                    localStorage.setItem("DarkMode", event.target.checked);
                    setDarkMode(event.target.checked);
                  }
                }
                id="this"
                onIcon={<CgSun />}
                offIcon={<CgMoon />}
              />
            </SideMenu>
            <main className="container-fluid">
              <div className="row fit-row">
                <div className="col-12">
                  <Routes>
                    <Route path="/logout/" element={<LogOut />} />

                    <Route path="/scan-qr/" element={<ScanQr />} />
                    <Route path="/view-forms/" element={<ViewForms />} />
                    <Route path="/form-data/" element={<ViewForms />} />
                    <Route path="/form-data/:slug" element={<ViewFormData />} />
                    <Route path="/data-entry/:slug/" element={<DataEntry />} />
                    <Route path="/data-entry/:slug/:slugb/" element={<DataEntry />} />

                    <Route path="/table" element={<Table />} />
                    <Route path="/cards" element={<Cards />} />
                    <Route path="/gallery" element={<Gallery />} />
                    <Route path="/forms" element={<Forms />} />
                    <Route path="/forms/x-thusiast" element={<XThusiastForms />}
                    />
                    <Route path="/forms/x-thusiast/single" element={<XThusiastSingle />}
                    />
                    <Route path="/" element={<ViewForms />} />
                    <Route element={<NotFound />} />
                  </Routes>
                </div>
              </div>
            </main>
            <Footer />
          </div>
        </Router>
      </>
    );
  }

}

export default App;
