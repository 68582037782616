import React from "react";
import Breadcrumb from "../../components/Breadcrumb";
import { FormsCrumbs } from "../../utils/BreadcrumbData";
import Collapse from "../../components/Collapse";
import CardCollapse from "../../components/CardCollapse";
import Card from "../../components/Card";
import { VscPinned } from "react-icons/vsc";

function Cards() {
  return (
    <>
      <Breadcrumb items={FormsCrumbs} />

      <Card alert="info" closable>
        <p>Info - closable cards are optional</p>
      </Card>

      <Card alert="error" closable>
        <p>Error</p>
      </Card>

      <Card alert="warning" closable>
        <p>Warning</p>
      </Card>

      <Card alert="success" closable>
        <p>Success</p>
      </Card>

      <CardCollapse title="Collapse Card" open>
        <p>Content</p>
      </CardCollapse>

      <Card title="Card title" icon={<VscPinned />} subTitle="Card subtitle">
        <p>Some content for my card</p>
        <Collapse label="Collapse Item">hello</Collapse>
      </Card>
    </>
  );
}

export default Cards;
