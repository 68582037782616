import React, { useState } from "react"
import { NavLink } from "react-router-dom"
import Collapse from "./Collapse"

const SideMenuSub = ({ item }) => {
  const [clickToggle, setClickToggle] = useState(true)
  function ButtonClick() {
    setClickToggle(!clickToggle)
  }

  function menuContent() {
    return (
      <>
        {item.icon}
        {item.title}
      </>
    );
  }

  return (
    <>
      {item.subNav ? (
        <>
          <Collapse
            label={
              <div
                className="menu-item parent"
                onClick={ButtonClick}
              >
                {menuContent()}
              </div>
            }
          >
            <div
              className="sub-menu"
            >
              {item.subNav.map((item, index) => {
                return (
                  <div className="sub-menu-item" key={index}>
                    <NavLink to={item.path}>
                      {item.icon}
                      {item.title}
                    </NavLink>
                  </div>
                );
              })}
            </div>
          </Collapse>
        </>
      ) : (
        <NavLink to={item.subNav ? "#" : item.path} onClick={item.subNav} >
          <div className="menu-item">{menuContent()}</div>
        </NavLink>
      )}
    </>
  );
};

export default SideMenuSub
