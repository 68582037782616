import React from "react";

function ToggleSwitch({ onChange, ...props }) {

  var isChecked = false;
  if (props.checked === "true" || props.checked === true) {
    isChecked = true;
  }

  return (
    <div className="toggle-switch">
      <input defaultChecked={isChecked} type="checkbox" id={props.id} onChange={onChange} />
      <label htmlFor={props.id}>
        {props.onIcon}
        {props.offIcon}
      </label>
    </div>
  );
}

export default ToggleSwitch;
