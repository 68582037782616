import React, { useState } from "react";
import Breadcrumb from "../components/Breadcrumb";
import Card from "../components/Card";
import { NavLink } from "react-router-dom";
// import CardFooter from "../components/CardFooter";
import Gallery from "../components/Gallery";
import GalleryVideo from "../components/GalleryVideo";

import { useParams } from "react-router-dom";
import { getGlobal, setGlobal } from "reactn";
import TextareaAutosize from "react-textarea-autosize";
import axios from "axios";
import {
  VscPinned,
  VscFileMedia,
  VscBook,
  VscCommentDiscussion,
  VscFiles,
  VscSave,
  VscWarning,
  VscCloudDownload,
  VscArrowSmallLeft,
  VscArrowSmallRight,
  VscEdit,
} from "react-icons/vsc";

import APIget from "../helpers/APIget";
import decodeEntities from "../helpers/decodeEntities";
import serialize from "../helpers/serialize";

import Loading from "../components/Loading";
//import ActionFilters from "../components/ActionFilters";

export default function DataEntry() {
  let { slug, slugb } = useParams();

  //const [filterTrigger, setFilterTrigger] = useState(0);

  let DataEntryCrumbs = [];

  const [formLoading, setFormLoading] = useState(0);
  const [updateState, setUpdateState] = useState(0);

  const GetFormId = () => {

    var apiUrl = "/get-data-entry-id/" + slug + "/";
    const { isLoaded, data } = APIget(apiUrl);

    if (!isLoaded) {
      return (
        <>
          <Breadcrumb items={DataEntryCrumbs} />
          <Loading />
        </>
      );
    }

    if (isLoaded) {

      var thisFormID = 0;
      if (data.form_id !== null || data.form_id !== "null") {
        thisFormID = data.form_id
      }

      return (
        <>
          <GetData formID={thisFormID} />
        </>
      )
    }
  }


  const GetData = (props) => {

    var filter_0_item = "admin-action-" + props.formID + "-0";
    var filter_0_setting = localStorage.getItem(filter_0_item);
    if (filter_0_setting !== null) { filter_0_setting === true || filter_0_setting === "true" ? filter_0_setting = 1 : filter_0_setting = 0 } else { filter_0_setting = 0 }

    var filter_1_item = "admin-action-" + props.formID + "-1";
    var filter_1_setting = localStorage.getItem(filter_1_item);
    if (filter_1_setting !== null) { filter_1_setting === true || filter_1_setting === "true" ? filter_1_setting = 1 : filter_1_setting = 0 } else { filter_1_setting = 0 }

    var filter_2_item = "admin-action-" + props.formID + "-2";
    var filter_2_setting = localStorage.getItem(filter_2_item);
    if (filter_2_setting !== null) { filter_2_setting === true || filter_2_setting === "true" ? filter_2_setting = 1 : filter_2_setting = 0 } else { filter_2_setting = 0 }

    var filter_3_item = "admin-action-" + props.formID + "-3";
    var filter_3_setting = localStorage.getItem(filter_3_item);
    if (filter_3_setting !== null) { filter_3_setting === true || filter_3_setting === "true" ? filter_3_setting = 1 : filter_3_setting = 0 } else { filter_3_setting = 0 }

    var filter_4_item = "admin-action-" + props.formID + "-4";
    var filter_4_setting = localStorage.getItem(filter_4_item);
    if (filter_4_setting !== null) { filter_4_setting === true || filter_4_setting === "true" ? filter_4_setting = 1 : filter_4_setting = 0 } else { filter_4_setting = 0 }

    var filter_5_item = "admin-action-" + props.formID + "-5";
    var filter_5_setting = localStorage.getItem(filter_5_item);
    if (filter_5_setting !== null) { filter_5_setting === true || filter_5_setting === "true" ? filter_5_setting = 1 : filter_5_setting = 0 } else { filter_5_setting = 0 }

    var filter_6_item = "admin-action-" + props.formID + "-6";
    var filter_6_setting = localStorage.getItem(filter_6_item);
    if (filter_6_setting !== null) { filter_6_setting === true || filter_6_setting === "true" ? filter_6_setting = 1 : filter_6_setting = 0 } else { filter_6_setting = 0 }

    var filter_7_item = "admin-action-" + props.formID + "-7";
    var filter_7_setting = localStorage.getItem(filter_7_item);
    if (filter_7_setting !== null) { filter_7_setting === true || filter_7_setting === "true" ? filter_7_setting = 1 : filter_7_setting = 0 } else { filter_7_setting = 0 }

    var filter_8_item = "admin-action-" + props.formID + "-8";
    var filter_8_setting = localStorage.getItem(filter_8_item);
    if (filter_8_setting !== null) { filter_8_setting === true || filter_8_setting === "true" ? filter_8_setting = 1 : filter_8_setting = 0 } else { filter_8_setting = 0 }

    var filter_9_item = "admin-action-" + props.formID + "-9";
    var filter_9_setting = localStorage.getItem(filter_9_item);
    if (filter_9_setting !== null) { filter_9_setting === true || filter_9_setting === "true" ? filter_9_setting = 1 : filter_9_setting = 0 } else { filter_9_setting = 0 }

    var filter_10_item = "admin-action-" + props.formID + "-10";
    var filter_10_setting = localStorage.getItem(filter_10_item);
    if (filter_10_setting !== null) { filter_10_setting === true || filter_10_setting === "true" ? filter_10_setting = 1 : filter_10_setting = 0 } else { filter_10_setting = 0 }


    var apiUrl = "/data-entry/" + slug + "/" + filter_0_setting + "/" + filter_1_setting + "/" + filter_2_setting + "/" + filter_3_setting + "/" + filter_4_setting + "/" + filter_5_setting + "/" + filter_6_setting + "/" + filter_7_setting + "/" + filter_8_setting + "/" + filter_9_setting + "/" + filter_10_setting + "/";

    const { isLoaded, data } = APIget(apiUrl);

    if (!isLoaded) {
      return (
        <>
          <Breadcrumb items={DataEntryCrumbs} />
          <Loading />
        </>
      );
    }

    if (isLoaded) {

      setGlobal({ csrfToken: data.newtoken });

      DataEntryCrumbs = [
        {
          title: "Forms",
          path: "view-forms/",
        },
        {
          title: data.form_name,
          path: "form-data/" + data.form_id + "/",
        },
        {
          title: "#" + data.submission_id,
          path: data.submission_id + "/",
        },
      ];

      const EntryData = [
        {
          id: data.submission_id,
          entryHeader: "Form Submission",
          subheader: data.form_name,
          comments: data.comments,
          otherComments: data.other_comments,
          Details: data.details,
          Actions: data.actions,
          PostFiles: data.postfiles,
          Images: data.postimages,
          Videos: data.postvideos ? data.postvideos : [],
          OtherDetails: data.other_details,
        },
      ];


      var MODtoogleName = "admin-action-" + data.form_id + "-0";
      var MODsavedToogleState = localStorage.getItem(MODtoogleName);
      if (MODsavedToogleState === null || MODsavedToogleState === "null") {
        MODsavedToogleState = false;
        localStorage.setItem(MODtoogleName, false);
      }

      if (data.status === 1) {
        return (
          <>
            <Breadcrumb items={DataEntryCrumbs} />

            {/* <ActionFilters
              MODtoogleName={MODtoogleName}
              MODsavedToogleState={MODsavedToogleState}
              actionFilters={data.action_filters}
              setFilterTrigger={setFilterTrigger}
              filterTrigger={filterTrigger}
            /> */}

            {slugb === "1" ? (
              <>
                <Card alert="warning" closable>
                  <p>This QR code has already been used for check-in</p>
                </Card>
              </>
            ) : null} 

            {updateState === 1 ? (
              <>
                <Card alert="error" closable>
                  <p>Error updating, please refresh and try again</p>
                </Card>
              </>
            ) : null}

            {updateState === 2 ? (
              <>
                <Card alert="success" closable>
                  <p>Entry has been updated successfully</p>
                </Card>
              </>
            ) : null}

            <form
              className={[
                formLoading === 1 ? "loading-form" : null,
                "data-entry-form",
              ].join(" ")}
              onSubmit={handleSubmit}
            >
              {EntryData.map((item, index) => (
                <div key={index}>
                  <div className="row">
                    <div className={item.Images.length || item.Videos.length  ? "col-6" : "col-12"}>
                      <Card
                        title={item.entryHeader + ` #${item.id}`}
                        subTitle={item.subheader}
                        icon={<VscPinned />}
                      >

                        {data.readonly === 0 ? (
                          <>
                            <div className="state-checkboxes">

                              <h2>Actions</h2><br />

                              {item.Actions.map((item, index) => {

                                return (
                                  <div
                                    className="checkbox"
                                    key={item.action_number}
                                  >
                                    <input
                                      type="checkbox"
                                      id={item.action_name}
                                      name={item.action_name}
                                      defaultChecked={
                                        item.action_value === "1" ||
                                          item.action_value === 1
                                          ? "checked"
                                          : null
                                      }
                                      data-value={
                                        item.action_value === "1" ||
                                          item.action_value === 1
                                          ? "1"
                                          : "0"
                                      }
                                      value={
                                        item.action_value === "1" ||
                                          item.action_value === 1
                                          ? "1"
                                          : "0"
                                      }
                                      onChange={(e) => {

                                        SetIsUsavedChanges(true);

                                        if (e.target.checked) {
                                          e.target.setAttribute(
                                            "data-value",
                                            "1"
                                          );
                                        } else {
                                          e.target.setAttribute(
                                            "data-value",
                                            "0"
                                          );
                                        }
                                      }}
                                    />
                                    <div className="input-helper"></div>
                                    <label htmlFor={item.action_name}>
                                      {item.action_label}
                                    </label>
                                  </div>
                                );
                              })}
                            </div>
                            <hr />
                          </>
                        ) : null}


                        <h2>Key Details</h2>
                        <table className="table">
                          <tbody>
                            {item.Details.map((item, index) => {

                              const GetEntry = () => {

                                return (

                                  <tr key={index}>
                                    <td>{item.question}</td>
                                    <td>

                                      {item.editable === 1 && data.readonly === 0 ? (
                                        <>

                                          {item.type === "text" ? (
                                            <>
                                              <input
                                                className="admin-text-override"
                                                type="text"
                                                name={item.field_name}
                                                defaultValue={decodeEntities(item.answer)}
                                                placeholder={decodeEntities(item.answer)}
                                                onChange={(e) => {
                                                  SetIsUsavedChanges(true);
                                                }}
                                              />
                                              <div className="edit-entry-trigger"><VscEdit /></div>
                                            </>
                                          ) : null}

                                          {item.type === "email" ? (
                                            <>
                                              <input
                                                className="admin-text-override"
                                                type="text"
                                                name={item.field_name}
                                                defaultValue={decodeEntities(item.answer)}
                                                placeholder={decodeEntities(item.answer)}
                                                onChange={(e) => {
                                                  SetIsUsavedChanges(true);
                                                }}
                                              />
                                              <div className="edit-entry-trigger"><VscEdit /></div>
                                            </>
                                          ) : null}

                                        </>
                                      ) : (
                                        <>
                                          <span dangerouslySetInnerHTML={{ __html: item.answer }} />
                                        </>
                                      )}

                                    </td>
                                  </tr>
                                )

                              }
                              return GetEntry();

                            })}
                          </tbody>
                        </table>


                      </Card>
                    </div>

                    {item.Images.length ? (
                      <div className="col-6">
                        <Card
                          title="Submitted Images"
                          subTitle=""
                          icon={<VscFileMedia />}
                        >
                          <Gallery
                            entryID={item.id}
                            formID={data.form_id}
                            data={item.Images}
                            cols={{ default: 3, 1200: 2 }}
                            lightbox
                          />
                        </Card>
                      </div>
                    ) : null}

                    {/* working here */}
                    { item.Videos.length? ( 
                   
                      <div className="col-6">
                        <Card
                          title="Submitted Videos"
                          subTitle=""
                          icon={<VscFileMedia />}
                        >
                          <GalleryVideo
                            entryID={item.id}
                            formID={data.form_id}
                            data={item.Videos}
                            cols={{ default: 3, 1200: 2 }}
                            lightbox
                          />
                        </Card>
                      </div>
                    ) : null}

                  </div>

                  {item.OtherDetails.length ? (
                    <div className="row">
                      <div className="col-12">
                        <Card
                          title="Other Details"
                          subTitle=""
                          icon={<VscBook />}
                        >
                          {item.OtherDetails.map((item, index) => {

                            return (
                              <div key={index}>
                                <p>{decodeEntities(item.question)}</p>


                                {item.editable === 1 ? (
                                  <>
                                    <div className="editable-text-area-container">
                                      <TextareaAutosize
                                        minRows="1"
                                        name={item.field_name}
                                        rows="1"
                                        defaultValue={decodeEntities(item.answer)}
                                        placeholder={decodeEntities(item.answer)}
                                        onChange={(e) => {
                                          SetIsUsavedChanges(true);
                                        }}
                                      />
                                      <div className="edit-entry-trigger"><VscEdit /></div>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                      <div className="muted-textarea"
                                        dangerouslySetInnerHTML={{ __html: item.answer }}
                                      />
                                  </>
                                )}

                              </div>
                            )
                          })}
                        </Card>
                      </div>
                    </div>
                  ) : null}

                  {item.PostFiles.length ? (
                    <div className="row">
                      <div className="col-12">
                        <Card title="Files" subTitle="" icon={<VscFiles />}>
                          <table className="table"><tbody>
                            {item.PostFiles.map((item, index) => (
                              <tr key={index}>
                                <td>
                                  <VscCloudDownload />
                                  <a
                                    className="other-files-link"
                                    target="_blank"
                                    rel="noreferrer"
                                    href={getGlobal().cdnURL + "/" + item.url}
                                  >
                                    {item.fileName}
                                  </a>
                                </td>
                              </tr>
                            ))}
                          </tbody></table>
                        </Card>
                      </div>
                    </div>
                  ) : null}
                  <div className="row">
                    <div className="col-12">
                      <Card
                        title="Admin Comments"
                        subTitle=""
                        icon={<VscCommentDiscussion />}
                      >
                        {data.readonly === 0 ? (
                          <>
                            <TextareaAutosize
                              minRows="3"
                              name="comments"
                              rows="4"
                              defaultValue={decodeEntities(item.comments)}
                              placeholder="Write a comment!"
                              onChange={(e) => {

                                SetIsUsavedChanges(true);

                              }}
                            />
                          </>
                        ) : null}

                        {item.otherComments.map((item, index) => (
                          <div className="other-comment" key={index}>
                            <h4 className="other-comment-header">
                              <small>
                                <VscPinned /> Posted by&nbsp;
                                <strong>{item.display_name}</strong>, last
                                updated {item.last_update}
                              </small>
                            </h4>
                            <TextareaAutosize
                              readOnly
                              minRows="3"
                              name={"comments__" + index}
                              rows="4"
                              defaultValue={decodeEntities(item.content)}
                            />
                          </div>
                        ))}
                      </Card>
                    </div>

                    {data.readonly === 0 ? (
                      <>
                        <Breadcrumb items={DataEntryCrumbs}>
                          <div className="save-entry">

                            <span className="unsavedChangesNotice">
                              <VscWarning /> unsaved changes
                            </span>

                            <button
                              className="btn btn-save"
                              type="submit"
                              value="Submit"
                            >
                              <VscSave /> Save Entry
                            </button>
                            <div className="breadcrumb-arrows">

                              {data.go_left_entry === 0 ? null : (
                                <>
                                  <NavLink
                                    to={"/data-entry/" + data.go_left_entry + "/"}
                                  >
                                    <div><VscArrowSmallLeft /></div>
                                  </NavLink>
                                </>
                              )}
                              &nbsp;
                              {data.go_right_entry === 0 ? null : (
                                <>
                                  <NavLink
                                    to={"/data-entry/" + data.go_right_entry + "/"}
                                  >
                                    <div><VscArrowSmallRight /></div>
                                  </NavLink>
                                </>
                              )}
                            </div>
                          </div>
                        </Breadcrumb>
                      </>
                    ) : null}
                  </div>
                </div>
              ))}
            </form>
          </>
        );
      } else {
        var errEntryCrumbs = [];

        return (
          <>
            <Breadcrumb items={errEntryCrumbs} />
            <Card alert="error">
              <p>YOU DO NOT HAVE PERMISSION TO VIEW THIS DATA</p>
            </Card>
          </>
        );
      }
    }
  };



  function SetIsUsavedChanges(state) {

    let usavedChangesDiv = document.querySelector('.unsavedChangesNotice');
    if (usavedChangesDiv !== null) {

      if (state) {
        usavedChangesDiv.classList.add('showing-warning');
      } else {
        usavedChangesDiv.classList.remove('showing-warning');
      }

    }

    return false;

  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const dataEntryForm = document.querySelector(".data-entry-form");
    const serializedData = serialize(dataEntryForm);

    if (formLoading === 0) {
      setFormLoading(1);

      const formData = new FormData();
      formData.append("Token", getGlobal().Token);
      formData.append("CSRFtoken", getGlobal().csrfToken);

      for (var i = 0; i < serializedData.length; i++) {
        var obj = serializedData[i];
        formData.append(obj.name, obj.value);
      }

      var apiARL = getGlobal().ApiURL + "/update-data-entry/" + slug + "/";
      const res = await axios.post(apiARL, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (res.data.status === 0) {

        setFormLoading(0);
        setUpdateState(1);
      }
      if (res.data.status === 1) {
        setGlobal({ csrfToken: res.data.newtoken });

        setFormLoading(0);
        setUpdateState(2);
      }
    }

    return false;
  };

  return (
    <>
      <GetFormId />
    </>
  );
}
