import React from "react";
import Breadcrumb from "../../components/Breadcrumb";
import Card from "../../components/Card";
import CardFooter from "../../components/CardFooter";
import Gallery from "../../components/Gallery";
import Collapse from "../../components/Collapse";
import {
  IoLogoTiktok,
  IoLogoTwitter,
  IoLogoInstagram,
  IoLogoYoutube,
  IoGlobeOutline,
  IoLogoFacebook,
} from "react-icons/io5";
import {
  VscPinned,
  VscFileMedia,
  VscBook,
  VscCommentDiscussion,
} from "react-icons/vsc";

const baseURL = window.location.origin;

const EntryData = [
  {
    id: "1928",
    fullName: "John Smith",
    type: "1",
    comments: "13234",

    Details: [
      {
        question: "Preferred pronoun",
        answer: "He",
      },
      {
        question: "Email address",
        answer: "j.smith@gmail.com",
      },
      {
        question: "Country",
        answer: "UK",
      },
      {
        question: "Age",
        answer: "26",
      },
      {
        question: "Products",
        answer: "X Series, GFX",
      },
      {
        question: "Paid or gifting",
        answer: "Paid only",
      },
    ],
    Actions: [
      {
        shortlisted: "1",
        blog: "0",
        social: "0",
        rejected: "1",
      },
    ],
    Social: [
      {
        instagram: "Jsmith001",
        instagramFollowing: "10k - 50k",
        theme: "Beauty, fashion, animals/pet",
        instagramDemographics: "Young men who like sports cars",
      },
    ],
    otherSocial: [
      {
        social: "Facebook",
        handle: "smith019181",
      },
      {
        social: "Twitter",
        handle: "@JSmith",
      },
      {
        social: "YouTube",
        handle: "Xyj81",
      },
      {
        social: "TikTok",
        handle: "JSmith",
      },
      {
        social: "Website/blog",
        handle: "www.jsmith.com",
      },
    ],
    Images: [
      {
        id: "1",
        url: baseURL + "/images/img1.jpg",
        fileName: "img1.jpg",
      },
      {
        id: "2",
        url: baseURL + "/images/img2.jpg",
        fileName: "img2.jpg",
      },
      {
        id: "3",
        url: baseURL + "/images/img3.jpg",
        fileName: "img3.jpg",
      },
      {
        id: "4",
        url: baseURL + "/images/img4.jpg",
        fileName: "img4.jpg",
      },
      {
        id: "5",
        url: baseURL + "/images/img5.jpg",
        fileName: "img5.jpg",
      },
      {
        id: "6",
        url: baseURL + "/images/img6.jpg",
        fileName: "img6.jpg",
      },
    ],
    Description: [
      {
        question: "How do you want to collaborate with us?",
        answer: "Share my images with the Fujifilm community.",
      },
      {
        question: "In as much detail as possible, tell us about your project:",
        answer:
          "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.",
      },
      {
        question:
          "Which product category or Fujifilm product do you see working for this collaboration?",
        answer: "GFX, Lens.",
      },
      {
        question: "Is there a deadline?",
        answer: "No.",
      },
    ],
  },
];

const XThusiastSingleCrumbs = [
  {
    title: "Forms",
    path: "forms",
  },
  {
    title: "X-thusiast",
    path: "forms/x-thusiast",
  },
  {
    title: "1928",
    path: "1928",
  },
];


function XthusiastSingle() {
  return (
    <>
      <Breadcrumb items={XThusiastSingleCrumbs} />

      {EntryData.map((item, index) => (
        <div key={index}>
          <div className="row">
            <div className="col-6">
              {(() => {
                if (item.type === "0") {
                  item.type =
                    "Current photographer or videographer using Fujifilm";
                } else if (item.type === "1") {
                  item.type =
                    "Content creator looking to collaborate on a specific project";
                } else if (item.type === "2") {
                  item.type =
                    "Content creator looking to introduce myself to Fujifilm";
                }
                return null;
              })()}
              <Card
                title={item.fullName + ` — #${item.id}`}
                subTitle={item.type}
                icon={<VscPinned />}
              >
                <h2>Details</h2>
                <table className="table">
                  <tbody>
                    {item.Details.map((item, index) => (
                      <tr key={index}>
                        <td>{item.question}</td>
                        <td>{item.answer}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <div className="xthusiast-social" key={index}>
                  <h2>Social media</h2>
                  <table className="table">
                    {item.Social.map((item, index) => (
                      <tbody key={index}>
                        <tr>
                          <td>
                            <IoLogoInstagram color="#E1306C" />
                            <span>Instagram</span>
                          </td>
                          <td>
                            {item.instagram} / {item.instagramFollowing}{" "}
                            following
                          </td>
                        </tr>
                        <tr>
                          <td>Demographics</td>
                          <td>{item.instagramDemographics}</td>
                        </tr>
                        <tr>
                          <td>Channel theme(s)</td>
                          <td>{item.theme}</td>
                        </tr>
                      </tbody>
                    ))}
                  </table>

                  <Collapse
                    label="Other social media"
                    className="collapse-social"
                  >
                    <table className="table">
                      <tbody>
                        {item.otherSocial.map((item, index) => (
                          <tr key={index}>
                            <td>
                              {(() => {
                                if (item.social === "Facebook") {
                                  return <IoLogoFacebook color="#4267B2" />;
                                } else if (item.social === "Twitter") {
                                  return <IoLogoTwitter color="#1DA1F2" />;
                                } else if (item.social === "YouTube") {
                                  return <IoLogoYoutube color="#C4302B" />;
                                } else if (item.social === "TikTok") {
                                  return <IoLogoTiktok color="#1DA1F2" />;
                                } else if (item.social === "Website/blog") {
                                  return <IoGlobeOutline color="#FF7600" />;
                                }
                                return null;
                              })()}
                              <span>{item.social}</span>
                            </td>
                            <td>{item.handle}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Collapse>
                </div>

                {item.Actions.map((item, index) => (
                  <div className="xthusiast-checkboxes" key={index}>
                    <h2>Actions</h2>
                    <div>
                      <input
                        type="checkbox"
                        id="shortlist"
                        name="shortlist"
                        defaultChecked={
                          item.shortlisted === "1" ? "checked" : null
                        }
                      />
                      <label htmlFor="shortlist">Shortlist</label>
                    </div>

                    <div>
                      <input
                        type="checkbox"
                        id="blog"
                        name="blog"
                        defaultChecked={item.blog === "1" ? "checked" : null}
                      />
                      <label htmlFor="blog">Blog</label>
                    </div>

                    <div>
                      <input
                        type="checkbox"
                        id="social"
                        name="social"
                        checked={item.social === "1" ? "checked" : null}
                      />
                      <label htmlFor="social">Social</label>
                    </div>

                    <div>
                      <input
                        type="checkbox"
                        id="rejected"
                        name="rejected"
                        defaultChecked={
                          item.rejected === "1" ? "checked" : null
                        }
                      />
                      <label htmlFor="rejected">Rejected</label>
                    </div>
                  </div>
                ))}
              </Card>
            </div>
            <div className="col-6">
              <Card
                title="Images"
                subTitle="User submitted images"
                icon={<VscFileMedia />}
              >
                <Gallery data={item.Images} cols={{default: 3, 1200: 2}} lightbox />
              </Card>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <Card
                title="Description"
                subTitle="User submitted description"
                icon={<VscBook />}
              >
                {item.Description.map((item, index) => (
                  <div key={index}>
                    <p>{item.question}</p>
                    <div className="muted-textarea">{item.answer}</div>
                  </div>
                ))}
              </Card>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <Card
                title="Comments"
                subTitle="Fujifilm comments"
                icon={<VscCommentDiscussion />}
              >
                <textarea
                  name="comments"
                  rows="4"
                  defaultValue={item.comments}
                />
                <CardFooter />
              </Card>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}
export default XthusiastSingle;
