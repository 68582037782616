import React, { useState } from "react";
import axios from "axios";
import { setGlobal, getGlobal } from 'reactn';

import { setCookie, setCookieTemp, eraseCookie } from '../helpers/Cookies.js';

export default function TwoFactorForm(props) {

  const [twoFaCode, setTwoFaCode] = useState();
  const [error, setError] = useState(0);
  const [formLoading, setFormLoading] = useState(0);
  const [rememberme, setRememberme] = useState(0);

  const handleSubmit = async e => {

    e.preventDefault();

    if (formLoading === 0) {

      setFormLoading(1);

      if (twoFaCode === undefined || twoFaCode === "") {
        setError(1);
        setFormLoading(0);
        return false;
      } else {
        loginUser().catch();
        async function loginUser() {

          const twoFaToken = getGlobal().twoFaToken;
          const CSRFtoken = getGlobal().csrfToken;

          const formData = new FormData();
          formData.append('twoFaCodetwoFaCode', twoFaCode);
          formData.append('twoFaToken', twoFaToken);
          formData.append('CSRFtoken', CSRFtoken);

          var apiARL = getGlobal().ApiURL + "/two_fa_submit/";
          const res = await axios.post(apiARL, formData, { headers: { 'Content-Type': 'multipart/form-data' } });

          if (res.data.status === 0) {

            setFormLoading(0);

            eraseCookie('P_PAT_Token');

          } else if (res.data.status === 1) {

            eraseCookie('P_PAT_Token');

            var token = res.data.Token;

            if (rememberme === 0) {
              setCookieTemp('P_PAT_Token', token);
            }
            if (rememberme === 1) {
              setCookie('P_PAT_Token', token, 31536000000);
            }

            setGlobal({
              LoggedIn: 1,
              Token: token,
            });


          } else {
            console.log("UNKNOWN ERROR!");
          }

        }

        return false;
      }

    } else {
      return false;
    }

  }


  function rememberMeToggle() {
    if (rememberme) {
      setRememberme(0);
    } else {
      setRememberme(1);
    }
  }

  return (
    <>
      <div className={["login-container", formLoading === 1 ? "loading" : null].join(" ")}>
        <div className={["login-form", formLoading === 1 ? "loading" : null].join(" ")}>
          <form onSubmit={handleSubmit}>
            <h1>Please enter the code we have emailed to you</h1>

            {error === 1 ? (<p className="login-feedback">Enter 2FA Code</p>) : null}

            <div>
              <label htmlFor="twoFaCode">2FA code</label>
              <input
                type="password"
                name="twoFaCode"
                placeholder="2FA code"
                onChange={e => {
                  setTwoFaCode(e.target.value);
                  setError(0);
                }}
              />
            </div>
            <div className="center-align">
              <div name="" className={[rememberme ? "active" : "not-active", "remember-me-checkbox"].join(' ')} onClick={e => {
                rememberMeToggle();
                setError(0);
              }}><span>Remember Me</span></div>
            </div>
            <div>
              <input type="submit" value="login" className={[formLoading === 1 ? "disabled" : null, "login-submit form-button"].join(' ')} />
            </div>

          </form>
        </div>
      </div>
    </>
  );
}
