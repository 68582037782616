import React, { useState } from "react";
import {
  VscError,
  VscThumbsup,
  VscInfo,
  VscWarning,
  VscClose,
} from "react-icons/vsc";

function Card(props) {
  const [closeCard, setCloseCard] = useState(true);

  function CloseButton() {
    setCloseCard(!closeCard);
  }

  return closeCard ? (
    <div
      className={props.className ? "card " + props.className : "card"}
      data-alert={props.alert}
    >
      {props.icon && (
        <div className="card-icon">{props.icon}</div>
      )}


      {props.alert && props.closable && (
        <VscClose className="close-card" onClick={CloseButton} />
      )}
      <div className="card-title-group">
        <div className="card-title">
          {props.title && <h2>{props.title}</h2>}
          {props.titleRight && <span>{props.titleRight}</span>}
        </div>
        {props.subTitle && <h3>{props.subTitle}</h3>}
        {props.title && props.children && <hr />}
      </div>
      {(() => {
        if (props.alert === "error") {
          return <VscError className="alert-icon" />;
        }
        if (props.alert === "success") {
          return <VscThumbsup className="alert-icon" />;
        }
        if (props.alert === "info") {
          return <VscInfo className="alert-icon" />;
        }
        if (props.alert === "warning") {
          return <VscWarning className="alert-icon" />;
        }
        return null;
      })()}
      {props.children}
    </div>
  ) : null;
}

export default Card;
