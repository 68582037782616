import Card from "../components/Card";
import { AiOutlineEye } from "react-icons/ai";
import ToggleSwitch from "../components/ToggleSwitch";

export default function ActionFilters(props) {

    function DoActionLoop(props) {

         if (props.actionFilters === undefined) {
            return null
        }

        return (
            <>
                {
                    props.actionFilters.map((action) => {

                        var toogleName = "admin-action-" + action.form_id + "-" + action.number;

                        var savedToogleState = localStorage.getItem(toogleName);
                        if (savedToogleState === null || savedToogleState === "null") {
                            savedToogleState = false;

                            if (action.filter_by_default === 0) {
                                localStorage.setItem(toogleName, false);
                            } else {
                                localStorage.setItem(toogleName, false);
                            }
                        }

                        return (

                            <div className="action-toggle-cell" key={toogleName}>
                                <p className="action-toggle-label">{action.name}</p>

                                <ToggleSwitch
                                    key={toogleName}
                                    checked={savedToogleState}
                                    onChange={
                                        (event) => {

                                            var currentState = localStorage.getItem(toogleName);

                                            currentState === false || currentState === "false" ? localStorage.setItem(toogleName, true) : localStorage.setItem(toogleName, false);
                                            props.filterTrigger === 1 ? props.setFilterTrigger(0) : props.setFilterTrigger(1);
                                        }
                                    }
                                    id={toogleName}
                                    onIcon={<AiOutlineEye />}
                                    offIcon={<AiOutlineEye className="hide-el" />}
                                />
                            </div>
                        )
                    })
                }
            </>

        )
    }
    

    return (
        <>
            <Card
                icon={<AiOutlineEye />}
            >
                <div className="action-toggle-container">

                    <div className="action-toggle-cell">
                        <p className="action-toggle-label">Unmoderated</p>
                        <ToggleSwitch
                            key={props.MODtoogleName}
                            checked={props.MODsavedToogleState}
                            onChange={
                                (event) => {

                                    var currentState = localStorage.getItem(props.MODtoogleName);
                                    currentState === false || currentState === "false" ? localStorage.setItem(props.MODtoogleName, true) : localStorage.setItem(props.MODtoogleName, false);
                                    props.filterTrigger === 1 ? props.setFilterTrigger(0) : props.setFilterTrigger(1);
                                }
                            }
                            id={props.MODtoogleName}
                            onIcon={<AiOutlineEye />}
                            offIcon={<AiOutlineEye className="hide-el" />}
                        />
                    </div>
                    
                    {props.actionFilters === null || props.actionFilters === "null" ? null : (
                        <>
                            <DoActionLoop actionFilters={props.actionFilters} filterTrigger={props.filterTrigger} setFilterTrigger={props.setFilterTrigger} />
                        </>
                    )}


                </div>
            </Card>
        </>
    )

}