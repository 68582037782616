import React, { useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useTable, useFilters, useSortBy, usePagination } from "react-table";
import { getGlobal, setGlobal } from "reactn";
import Breadcrumb from "../components/Breadcrumb";
import { FormDataCrumbs } from "../utils/BreadcrumbData";
import { VscOutput, VscTriangleUp, VscTriangleDown } from "react-icons/vsc";
import {
  CgChevronLeft,
  CgChevronRight,
  CgChevronDoubleLeft,
  CgChevronDoubleRight,
} from "react-icons/cg";
import Card from "../components/Card";
import APIget from "../helpers/APIget";
import Loading from "../components/Loading";
import { NavLink } from "react-router-dom";
import ActionFilters from "../components/ActionFilters";


import serialize from "../helpers/serialize";

import {
  VscFiles,
  VscCloudDownload,
} from "react-icons/vsc";

function ViewFormData() {

  let { slug } = useParams();

  const downloadFormData = async (e) => {
    e.preventDefault();
 
    const exportForm = document.getElementById('data-export-form');
    const serializedData = serialize(exportForm);
    let exportFieldKeys = "";

    for (var i = 0; i < serializedData.length; i++) {
      var obj = serializedData[i];
      var isChecked = document.getElementById(obj.name).checked;
      if (isChecked) {
        if (exportFieldKeys === "") {
          exportFieldKeys = exportFieldKeys + obj.name;
        } else {
          exportFieldKeys = exportFieldKeys + "," + obj.name;
        }
        
      }
    }

    const exportFormData = new FormData();
    exportFormData.append("Token", getGlobal().Token);
    exportFormData.append("CSRFtoken", getGlobal().csrfToken);
    exportFormData.append("fieldKeys", exportFieldKeys);

    var apiARL = getGlobal().ApiURL + "/download-form-data/" + slug + "/";
    const res = await axios.post(apiARL, exportFormData, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    

    window.open(res.data.download, '_blank', 'noopener,noreferrer');

  }

  function Table(props) {
    const data = props.entryData;
    const columns = props.dataHeaders;
    const defaultColumn = React.useMemo(
      () => ({
        Filter: DefaultColumnFilter,
      }),
      []
    );
    function DefaultColumnFilter({ column: { filterValue, setFilter } }) {
      return (
        <input
          data-form-type="other"
          value={filterValue || ""}
          onChange={(e) => {
            setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
          }}
          placeholder={`Search...`}
        />
      );
    }
    const filterTypes = React.useMemo(
      () => ({
        text: (rows, id, filterValue) => {
          return rows.filter((row) => {
            const rowValue = row.values[id];
            return rowValue !== undefined
              ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
              : true;
          });
        },
      }),
      []
    );
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      page,
      canPreviousPage,
      canNextPage,
      rows,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize },
    } = useTable(
      {
        columns,
        data,
        initialState: { pageSize: 10 },
        defaultColumn,
        filterTypes,
      },
      useFilters,
      useSortBy,
      usePagination
    );



    let { slug } = useParams();
    var MODtoogleName = "admin-action-" + slug + "-0";
    var MODsavedToogleState = localStorage.getItem(MODtoogleName);
    if (MODsavedToogleState === null || MODsavedToogleState === "null") {
      MODsavedToogleState = false;
      localStorage.setItem(MODtoogleName, false);
    }


    return (
      <>

        <Card
          title={props.formName}
          subTitle={rows.length + " total entries"}
          icon={<VscOutput />}
        >
          <table {...getTableProps()} className="table">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      <div className="filter-title">
                        {column.canGroupBy ? (
                          <span {...column.getGroupByToggleProps()}></span>
                        ) : null}
                        <span {...column.getSortByToggleProps()}>
                          {column.render("Header")}
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <VscTriangleUp />
                            ) : (
                              <VscTriangleDown />
                            )
                          ) : null}
                        </span>
                      </div>
                      <div className="th-filter">
                        {column.canFilter ? column.render("Filter") : null}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {

                      let cellValue = cell.value;

                      if (typeof cellValue === 'string' || cellValue instanceof String) {
                        cellValue = cellValue.replace("<br />", '\n');
                        cellValue = cellValue.replace("<br />", '\n');
                        cellValue = cellValue.replace("<br />", '\n');
                        cellValue = cellValue.replace("<br />", '\n');
                        cellValue = cellValue.replace("<br />", '\n');
                        cellValue = cellValue.replace("<br />", '\n');
                        cellValue = cellValue.replace("<br />", '\n');
                        cellValue = cellValue.replace("<br />", '\n');
                      }

                      return (
                        <td {...cell.getCellProps()}>
                          <NavLink
                            className="data-entry-link"
                            to={"/data-entry/" + cell.row.original.col1 + "/"}
                            dangerouslySetInnerHTML={{ __html: cellValue  }}
                          />
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="pagination">
            <div className="pagination-nav">
              <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                <CgChevronDoubleLeft />
              </button>
              <button
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                <CgChevronLeft />
              </button>
              <button onClick={() => nextPage()} disabled={!canNextPage}>
                <CgChevronRight />
              </button>
              <button
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                <CgChevronDoubleRight />
              </button>
            </div>
            <span>
              Page&nbsp;
              <strong>
                {pageIndex + 1} of {pageCount}
              </strong>
            </span>
            <span>|</span>
            <span>
              Go to page:
              <input
                type="number"
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
              />
            </span>
            <select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[5, 10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
        </Card>
      </>
    );
  }

  const GetData = () => {

    let { slug } = useParams();
    const [filterTrigger, setFilterTrigger] = useState(0);

    var filter_0_item = "admin-action-" + slug + "-0";
    var filter_0_setting = localStorage.getItem(filter_0_item);
    if (filter_0_setting !== null) { filter_0_setting === true || filter_0_setting === "true" ? filter_0_setting = 1 : filter_0_setting = 0 } else { filter_0_setting = 0 }

    var filter_1_item = "admin-action-" + slug + "-1";
    var filter_1_setting = localStorage.getItem(filter_1_item);
    if (filter_1_setting !== null) { filter_1_setting === true || filter_1_setting === "true" ? filter_1_setting = 1 : filter_1_setting = 0 } else { filter_1_setting = 0 }

    var filter_2_item = "admin-action-" + slug + "-2";
    var filter_2_setting = localStorage.getItem(filter_2_item);
    if (filter_2_setting !== null) { filter_2_setting === true || filter_2_setting === "true" ? filter_2_setting = 1 : filter_2_setting = 0 } else { filter_2_setting = 0 }

    var filter_3_item = "admin-action-" + slug + "-3";
    var filter_3_setting = localStorage.getItem(filter_3_item);
    if (filter_3_setting !== null) { filter_3_setting === true || filter_3_setting === "true" ? filter_3_setting = 1 : filter_3_setting = 0 } else { filter_3_setting = 0 }

    var filter_4_item = "admin-action-" + slug + "-4";
    var filter_4_setting = localStorage.getItem(filter_4_item);
    if (filter_4_setting !== null) { filter_4_setting === true || filter_4_setting === "true" ? filter_4_setting = 1 : filter_4_setting = 0 } else { filter_4_setting = 0 }

    var filter_5_item = "admin-action-" + slug + "-5";
    var filter_5_setting = localStorage.getItem(filter_5_item);
    if (filter_5_setting !== null) { filter_5_setting === true || filter_5_setting === "true" ? filter_5_setting = 1 : filter_5_setting = 0 } else { filter_5_setting = 0 }

    var filter_6_item = "admin-action-" + slug + "-6";
    var filter_6_setting = localStorage.getItem(filter_6_item);
    if (filter_6_setting !== null) { filter_6_setting === true || filter_6_setting === "true" ? filter_6_setting = 1 : filter_6_setting = 0 } else { filter_6_setting = 0 }

    var filter_7_item = "admin-action-" + slug + "-7";
    var filter_7_setting = localStorage.getItem(filter_7_item);
    if (filter_7_setting !== null) { filter_7_setting === true || filter_7_setting === "true" ? filter_7_setting = 1 : filter_7_setting = 0 } else { filter_7_setting = 0 }

    var filter_8_item = "admin-action-" + slug + "-8";
    var filter_8_setting = localStorage.getItem(filter_8_item);
    if (filter_8_setting !== null) { filter_8_setting === true || filter_8_setting === "true" ? filter_8_setting = 1 : filter_8_setting = 0 } else { filter_8_setting = 0 }

    var filter_9_item = "admin-action-" + slug + "-9";
    var filter_9_setting = localStorage.getItem(filter_9_item);
    if (filter_9_setting !== null) { filter_9_setting === true || filter_9_setting === "true" ? filter_9_setting = 1 : filter_9_setting = 0 } else { filter_9_setting = 0 }

    var filter_10_item = "admin-action-" + slug + "-10";
    var filter_10_setting = localStorage.getItem(filter_10_item);
    if (filter_10_setting !== null) { filter_10_setting === true || filter_10_setting === "true" ? filter_10_setting = 1 : filter_10_setting = 0 } else { filter_10_setting = 0 }


    var apiUrl = "/form-data/" + slug + "/" + filter_0_setting + "/" + filter_1_setting + "/" + filter_2_setting + "/" + filter_3_setting + "/" + filter_4_setting + "/" + filter_5_setting + "/" + filter_6_setting + "/" + filter_7_setting + "/" + filter_8_setting + "/" + filter_9_setting + "/" + filter_10_setting + "/";


    const { isLoaded, data } = APIget(apiUrl);

    if (!isLoaded) {
      return (
        <>
          <Loading />
        </>
      );
    }

    if (isLoaded) {

      setGlobal({ csrfToken: data.newtoken });

      let dataHeaders = data.data_headers;
      let entryData = data.entry_data;
      var validData = data.valid;


      var MODtoogleName = "admin-action-" + slug + "-0";
      var MODsavedToogleState = localStorage.getItem(MODtoogleName);
      if (MODsavedToogleState === null || MODsavedToogleState === "null") {
        MODsavedToogleState = false;
        localStorage.setItem(MODtoogleName, false);
      }

      return (
        <>

          <ActionFilters
            MODtoogleName={MODtoogleName}
            MODsavedToogleState={MODsavedToogleState}
            actionFilters={data.action_filters}
            setFilterTrigger={setFilterTrigger}
            filterTrigger={filterTrigger}
          />

          {entryData !== undefined ? (
            <>
              {validData === 0 ? (
                <>
                  <Card alert="error" closable>
                    <p>You don't have permission to view this page.</p>
                  </Card>
                </>
              ) : null}

              {entryData.length !== 0 && validData === 1 ? (
                <>
                  <Table
                    formName={data.form_name}
                    totalEntries={data.total_entries}
                    dataHeaders={dataHeaders}
                    entryData={entryData}
                    actionFilters={data.action_filters}
                  />
                </>
              ) : null}

              {entryData.length === 0 && validData === 1 ? (
                <>
                  <Card alert="warning">
                    <p>No entries</p>
                  </Card>
                </>
              ) : null}



              <Card
                title="EXPORT"
                icon={<VscFiles />}
              >

                <form id="data-export-form" onSubmit={downloadFormData}>

                  {data.export_fields.map((item, index) => (

                    <div
                      className="checkbox"
                      key={index}
                    >
                      <input
                        type="checkbox"
                        id={item.field_name}
                        name={item.field_name}
                        defaultChecked
                      />
                      <div className="input-helper"></div>
                      <label htmlFor={item.field_name}>
                        {item.label}
                      </label>
                    </div>

                  ))}


                  <button
                    className="btn btn-save btn-export"
                    type="submit"
                    value="Submit"
                  >
                    <VscCloudDownload /> EXPORT
                  </button>

                </form>

              </Card>



            </>
          ) : (
            <>
              <Card alert="error">
                <p>You do not have permission to view this data</p>
              </Card>
            </>
          )}
        </>
      );
    }
  };

  return (
    <>
      <Breadcrumb items={FormDataCrumbs} />
      <GetData />
    </>
  );
}
export default ViewFormData;
