import React, { useState } from 'react';
import { QrReader } from 'react-qr-reader';
import { useNavigate } from 'react-router-dom';

import APIget from "../helpers/APIget";

export default function ScanQr(props) {

    const [qrTriggered, setQrTriggered] = useState(false);

    const [qrData, setQrData] = useState('No result');

    let history = useNavigate();

    const QrScanner = (props) => {

        return (
            <>
                <QrReader
                    onResult={(result, error) => {
                        if (!!result) {
                            setQrData(result?.text);

                            setQrTriggered(true);
                            

                        }

                        if (!!error) {
                            //console.info("nope");
                        }
                    }}
                    scanDelay="50"
                    containerStyle={{ width: '100%', maxWidth: '720px' }}
                    constraints={{ facingMode: 'environment' }}
                />
            </>
        );
    };



    const GetQrData = () => {

        let qrUrl = "/check-qr/" + qrData + "/";

        const { isLoaded, data } = APIget(qrUrl);

        if (isLoaded) {

            if (data.status === 0) {
                setQrTriggered(false);
            }

            if (data.status === 1) {

                if (data.already_checked_in === 1) {
                    history('/data-entry/' + data.goto + '/1/');
                } else {
                    history('/data-entry/' + data.goto + '/');
                }
                
            }

        }

        return null;

    }





    if (qrTriggered) {
        
        return (

            <>
                <GetQrData />
            </>
        );

    } else {

        return (

            <>
                <QrScanner />
            </>
        );

    }



}

